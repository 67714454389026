.navbar-title {
  font-weight: 700;
}
.navbar-notes-logo {
  height: 63px;
  width: auto;
}
.navbar-notes-logo-small {
  height: 56px;
}
.brand-logo {
  margin: auto !important;
}
